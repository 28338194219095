/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-21 19:08:58
 * @LastEditors: AllenXD
 * @LastEditTime: 2022-05-25 15:51:26
 */
import { createNamespacedHelpers } from 'vuex'
import { wVerifyEmail } from '@/plugins/verifier'

const { mapGetters, mapActions } = createNamespacedHelpers('App/Finance/AccountConfig')

export default {
  name: 'AccountConfig',
  data () {
    const self = this
    return {
      rules: {
        name: [
          {
            required: true,
            message: '请输入用户名称',
            trigger: ['blur']
          },
          {
            max: 50,
            message: '用户名称不超过50个字符',
            trigger: ['blur']
          }
        ],
        product_name: [
          {
            required: true,
            message: '请输入用户名称',
            trigger: ['blur']
          },
          {
            max: 50,
            message: '用户名称不超过50个字符',
            trigger: ['blur']
          }
        ],
        first_trade_id: [
          {
            validator (rule, val, res) {
              if (self.form.first_trade_id === '' && self.form.second_trade_id === '') {
                res('请选择所属行业')
                return
              }
              if (self.form.second_trade_id === '') {
                res('请选择二级行业')
                return
              }
              res()
            },
            required: true,
            trigger: ['change']
          }
        ],
        contact_person_name: [
          {
            max: 50,
            message: '联系人不超过50个字符',
            trigger: ['blur']
          }
        ],
        phone: [
          {
            pattern: /^1\d{10}$/,
            message: '请输入正确的手机号',
            trigger: ['blur']
          }
        ],
        audience_desc: [
          {
            max: 500,
            message: '目标人群描述不超过500个字符',
            trigger: ['blur']
          }
        ],
        email: [
          {
            validator (rule, val, res) {
              if (val !== '' && !wVerifyEmail(val)) {
                res('请填写正确的电子邮箱')
              }
              res()
            },
            trigger: ['blur']
          }
        ]
      }
    }
  },
  computed: {

    ...mapGetters([
      'form',
      'tradeList',
      'firstTradeList',
      'secondTradeList'
    ]),

    /**
     * 一级行业分类
     */
    firstTradeOptions () {
      return this.firstTradeList
    },

    /**
     * 二级行业分类
     */
    secondTradeOptions () {
      const firstTradeId = this.form.first_trade_id
      if (!firstTradeId) {
        return []
      }
      return this.secondTradeList(firstTradeId)
    }
  },
  async mounted () {
    // 获取行业列表
    await this.getTradeList()
    // 获取资质详情
    await this.getAccountInfo()
  },
  methods: {

    ...mapActions([
      'getTradeList',
      'submitAccountConfig',
      'resetForm',
      'getAccountInfo',
      'updateAccountConfig'
    ]),

    /**
     * 预览图片
     */
    previewImg (file, index) {
      let url = ''
      if (file.response) {
        url = file.response.data
      } else {
        url = file.url
      }
      window.open(url)
    },

    /**
     * 上传验证
     */
    async uploadValidate () {
      const errorMsg = new Promise(resolve => this.$refs.form.validateField('business_url', resolve))
      return errorMsg
    },

    /**
     * 营业执照图片上传移除
     */
    async uploadRemove (file, fileList) {
      const valid = !this.uploadValidate()
      this.handelUpload('business_url', [])
      return valid
    },

    /**
     * 处理图片上传
     */
    handelUpload (filed, fileList) {
      this.form[filed] = fileList
    },

    /**
     * 营业执照图片上传成功
     */
    uploadSuccess (response, file, fileList) {
      if (response.code === 200) {
        this.handelUpload('business_url', [fileList[fileList.length - 1]])
        this.uploadValidate()
      }
    },

    /**
     * 重置表单
     */
    doResetForm () {
      this.resetForm()
      this.$refs.form.clearValidate()
    },

    /**
     * 取消
     */
    cancel () {
      this.doResetForm()
      // this.$router.push('/app/finance')
      this.$router.go(-1)
    },

    /**
     * 验证表单
     */
    validate () {
      const formEle = this.$refs.form
      return new Promise(resolve => formEle.validate(resolve))
    },

    /**
     * 提交
     */
    async submit () {
      const self = this
      const valid = await this.validate()
      console.log(valid)
      if (!valid) {
        this.$refs.submitButton.updatedLoading(false)
        return
      }
      const payload = [
        'name',
        'product_name',
        'first_trade_id',
        'second_trade_id',
        'business_url',
        'business_url_name',
        'contact_person_name',
        'phone',
        'email',
        'audience_desc'
      ].reduce((res, key) => {
        if (key === 'business_url') {
          const imgObject = self.form[key][0]
          if (imgObject) {
            if (imgObject.response) {
              res[key] = imgObject.response.data
            } else {
              res[key] = imgObject.url
            }
          } else {
            res[key] = ''
          }
        } else if (key === 'business_url_name') {
          res[key] = self.form.business_url.length ? self.form.business_url[0].name : ''
        } else {
          res[key] = self.form[key]
        }
        return res
      }, {})
      console.log(payload)
      const data = await this.submitAccountConfig(payload)
      this.$refs.submitButton.updatedLoading(false)

      if (data !== true) {
        return
      }
      this.$wToast.success('提交成功')
      await this.updateAccountConfig()
      this.doResetForm()
      history.go(-1)
    }
  }
}
