/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-21 19:08:27
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-21 19:09:51
 */
import AccountConfig from './AccountConfig.vue'
export default AccountConfig
